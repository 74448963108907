import { Field } from 'formik';

export function FormikFormField(props: {
  placeholder: string;
  value?: string;
  name?: string;
  class?: string;
  type?: string;
  label?: string;
}) {
  return (
    <div className="flex flex-col gap-2">
      {props.label && (
        <div className="text-[16px] text-black font-[500]">{props.label}</div>
      )}
      <Field
        component={props.type ?? 'input'}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        className={`bg-transparent underline-none border-solid border-[1px] border-[#C6C6C6] placeholder-[#C6C6C6] p-[0.56rem] outline-none ${props.class}`}
      />
    </div>
  );
}
