import { defaultStyles } from '@/utils/defaultStyles';
import { useState } from 'react';
import BurgerIcon from './BurgerIcon';
import { useRouter } from 'next/router';

export const routes = [
  {
    title: 'Strona główna',
    route: '/',
  },
  {
    title: 'Dojazd & parking',
    route: '/parking',
  },
  {
    title: 'Czynności notarialne',
    route: '/czynnosci-notarialne',
  },
  {
    title: 'Dokumenty & opłaty',
    route: '/dokumenty',
  },
  {
    title: 'Przydatne linki',
    route: '/przydatne-linki',
  },
  {
    title: 'Kontakt',
    route: '/kontakt',
  },
];

function Navbar(props: { withoutHero?: boolean; withBorder?: boolean }) {
  const [isBurgerToggled, setIsBurgerToggled] = useState(false);
  const location = useRouter();
  const url = location.pathname;

  return (
    <div className="h-[92px] fixed top-0 z-50 w-screen flex flex-col">
      <div
        className={`${defaultStyles.padding} bg-white border-[0.5px] border-solid border-[#A7A7A7] border-t-0 border-l-0 border-r-0`}
      >
        <nav
          className={`bg-white flex flex-row justify-between gap-4 items-center py-2`}
        >
          <img
            loading="lazy"
            src="/logo.png"
            alt="Kancelaria logo"
            className="w-[76px] h-[76px]"
          />
          <div className="hidden xl:flex flex-row gap-4 items-center">
            {routes.map((item, index) => (
              <button
                key={index}
                className={`text-[16px] font-bold border-[2px] border-solid border-t-0 border-r-0 border-l-0 bg-transparent hover:border-[#CBBA9D] hover:text-[#CBBA9D] transition-all duration-300 cursor-pointer ${item.route == url ? 'border-[#CBBA9D] text-[#CBBA9D]' : 'text-black border-transparent'} ${props.withoutHero ? '' : 'text-white'}`}
                onClick={() => {
                  if (item.route) {
                    window.location.assign(item.route);
                  }
                }}
              >
                {item.title}
              </button>
            ))}
          </div>
          <div className="flex xl:hidden flex-col justify-center items-center">
            <BurgerIcon
              isToggled={isBurgerToggled}
              onClick={() => setIsBurgerToggled(!isBurgerToggled)}
            />
          </div>
        </nav>
        {isBurgerToggled && (
          <div className="w-full bg-white pb-8">
            <div className="flex flex-col gap-4 items-start">
              {routes.map((item, index) => (
                <button
                  key={index}
                  className={`text-[16px] font-bold border-[2px] border-solid border-t-0 border-r-0 border-l-0 bg-transparent hover:border-[#CBBA9D] hover:text-[#CBBA9D] transition-all duration-300 cursor-pointer ${item.route == url ? 'border-[#CBBA9D] text-[#CBBA9D]' : 'text-black border-transparent'} ${props.withoutHero ? '' : 'text-white'}`}
                  onClick={() => {
                    if (item.route) {
                      window.location.assign(item.route);
                    }
                  }}
                >
                  {item.title}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
