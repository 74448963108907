export const modalIds = {
  basic: 'basic-modal',
};

export const API_URL = 'https://api.notariuszwroclawminska.pl/';

export const faqItems = [
  {
    title: 'Nieruchomości',
    desc: (
      <>
        - przedwstępna umowa sprzedaży
        <br />
        - umowa deweloperska
        <br />
        - sprzedaż
        <br />
        - darowizna
        <br />
        - dożywocie
        <br />
        - przeniesienie własności
        <br />
        - zniesienie współwłasności
        <br />
        - ustanowienie hipoteki
        <br />
        - depozyt
        <br />
        - wykup lokalu komunalnego przez najemcę
        <br />
        - warunkowa umowa sprzedaży
        <br />
        - służebności (mieszkania, przesyłu, zapewnienie dostępu do drogi)
        <br />
        - umowa zamiany
        <br />
        - umowa o rentę
        <br />
        - zbycie spółdzielczego własnościowego prawa do lokalu mieszkalnego
        <br />- ustanowienie odrębnej własności lokalu
      </>
    ),
  },
  {
    title: 'Spadki',
    desc: (
      <>
        - akt poświadczenia dziedziczenia
        <br />
        - testamenty
        <br />
        - otwarcie i ogłoszenie testamentu
        <br />
        - odrzucenie lub przyjęcie spadku
        <br />
        - odrzucenie spadku w imieniu małoletniego
        <br />
        - europejskie poświadczenie spadkowe
        <br />
        - zarząd sukcesyjny
        <br />
        - dział spadku
        <br />- zrzeczenie się dziedziczenia lub prawa do zachowku
      </>
    ),
  },
  {
    title: 'Spółki',
    desc: (
      <>
        - sprzedaż lub darowizna udziałów
        <br />
        - sprzedaż lub darowizna akcji
        <br />
        - zgromadzenie wspólników
        <br />
        - walne zgromadzenie akcjonariuszy
        <br />
        - umowa spółki
        <br />
        - rejestr akcjonariuszy
        <br />- oświadczenia o objęciu udziałów
      </>
    ),
  },
  {
    title: 'Poświadczenia',
    desc: (
      <>
        - poświadczenie własnoręczności podpisu lub odcisku palca
        <br />
        - poświadczenie zgodności odpisu z okazanym dokumentem
        <br />
        - wyciąg z dokumentu
        <br />
        - zgoda na wyjazd
        <br />
        - sprzedaż ogródka działkowego (RODO)
        <br />
        - elektroniczne poświadczenie zgodności odpisu z okazanym dokumentem
        (odpis elektroniczny)
        <br />
        - poświadczenie pozostawania przy życiu
        <br />- data pewna
      </>
    ),
  },
  {
    title: 'Protokoły',
    desc: (
      <>
        - protokoły zgromadzenia wspólników lub walnych zgromadzeń akcjonariuszy
        <br />
        - protokoły otwarcia strony internetowej
        <br />
        - protokół niestawiennictwa
        <br />- wspólnoty mieszkaniowe
      </>
    ),
  },
  {
    title: 'Sprawy rodzinne',
    desc: (
      <>
        - rozdzielność majątkowa
        <br />
        - podział majątku
        <br />
        - rozszerzenie ustawowej wspólności majątkowej małżeńskiej
        <br />- alimenty, ugody
      </>
    ),
  },
  {
    title: 'Egzekucje',
    desc: (
      <>
        - oświadczenie najemcy o poddaniu się egzekucji odnośnie obowiązku
        wydania lokalu
        <br />- zabezpieczenie zobowiązania zapłaty określonej kwoty
      </>
    ),
  },
  {
    title: 'Pełnomocnictwo',
    desc: <>- pełnomocnictwo</>,
  },
  {
    title: 'Fundacja',
    desc: <>- fundacja rodzinna</>,
  },
  {
    title: 'Najem okazjonalny',
    desc: <>- najem okazjonalny</>,
  },
];
