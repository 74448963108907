import { defaultStyles } from '@/utils/defaultStyles';
import { routes } from './Navbar';

function Footer() {
  return (
    <>
      <footer
        className={`${defaultStyles.padding} pb-6 flex flex-col justify-center items-center gap-8`}
      >
        <div className="flex flex-col gap-4 justify-center items-center">
          <img
            loading="lazy"
            src="/logo.png"
            alt="Kancelaria logo"
            className="w-[76px] h-[76px]"
          />
        </div>
        <div className="flex flex-col lg:flex-row justify-between w-full gap-4 py-8 items-center border border-solid border-l-0 border-r-0 border-[#E1E1E1]">
          {routes.map((item, index) => (
            <div
              onClick={() => {
                if (item.route) {
                  window.location.assign(item.route);
                }
              }}
              key={index}
              className="text-[18px] text-[#9A9EA6] cursor-pointer"
            >
              {item.title}
            </div>
          ))}
        </div>
        <div className="self-start">
          Assembled by Askay © {new Date().getFullYear()} All Rights Reserved
        </div>
      </footer>
    </>
  );
}

export default Footer;
